//Main Headings (Etc. Titles, Sub Titles)
$main-headings: 'latomedium';
$main-sub-headings: 'latoregular';
$main-sub-headings-italic: 'latomedium_italic';
$main-heading-italic: 'latoitalic';
$main-heading-bold: 'latobold';

$extra-bold: 'latoblack';

//Main Content Text (Paragraphs, Form, Etc)
$main-labels: 'open_sanssemibold';
$main-inputs: 'open_sansregular';
$main-bold: 'open_sansbold';
$main-italic: 'open_sansitalic';

//Font Sizes
$regular-size: 16px;
$medium-1-size: 17px;
$medium-2-size: 18px;
$secondary-size: 20px;

//Spacing
$alpha-space: 0.5px;
$alpha-space-2: 1px;

